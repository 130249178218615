<template>
  <v-card class="order-card" height="100%" :to="order.route">
    <!-- orderID/shipment number and items -->
    <div class="sec-1">
      <!-- left -->
      <div class="orderid pr-4">
        <div class="image-1">
          <img src="@/assets/static/myOrders/orderIdIcon.svg" alt="" />
        </div>
        <div class="content1">
          <h4 class="subtitle">Order Id/Shipment no</h4>
          <small>{{ order.orderId }}/ {{ order.shipmentNo }}</small>
        </div>
      </div>

      <!-- right -->
      <div>
        <!-- <img src="@/assets/static/myOrders/singleCard/item.svg" alt="" /> -->
        <h2>{{ order.itemInfo.length }}</h2>
        <h4 class="subtitle">No of items</h4>
      </div>
    </div>

    <!-- packing method -->
    <!-- sec-2 -->
    <div class="sec-2">
      <!-- left -->
      <div class="packaging pr-4">
        <div class="image-2">
          <img
            src="@/assets/static/myOrders/singleCard/packagingType.svg"
            alt=""
          />
        </div>
        <div class="content-2">
          <h4 class="subtitle">PackingType</h4>
          <small>{{ order.packageInfo.packingMethod }}</small>
        </div>
      </div>
      <!-- Total weight -->
      <!-- right -->
      <div class="weight">
        <div class="image-3">
          <img
            src="@/assets/static/myOrders/singleCard/totalWeight.svg"
            alt=""
          />
        </div>
        <div class="content-3">
          <h4 class="subtitle">Total Weight</h4>
          <small>{{ order.totalWeight }} grams</small>
        </div>
      </div>
    </div>
    <div class="hr"></div>

    <!-- order date -->
    <div class="sec-2">
      <!-- left -->
      <div class="packaging">
        <div class="image-2">
          <img src="@/assets/static/myOrders/singleCard/orderDate.svg" alt="" />
        </div>
        <div class="content-2">
          <h4 class="subtitle">Order Date</h4>
          <small
            >{{ getDate(order.createdAt) }} ({{
              getTime(order.createdAt)
            }})</small
          >
        </div>
      </div>

      <!-- total price and current status -->
      <!-- right -->
      <div class="weight pl-3">
        <div class="image-3">
          <img
            src="@/assets/static/myOrders/singleCard/totalPrice.svg"
            alt=""
          />
        </div>
        <div class="content-2">
          <h4 class="subtitle">Total Price</h4>
          <small v-if="order.priceDetails"
            >LKR {{ order.priceDetails.total }}.00</small
          >
          <small v-else>LKR {{ order.payMode.rate }}.00</small>
        </div>
        <div class="button" width="100px">
          <v-chip :color="order.currentStatus.color" class="statusBadge"
            ><span>{{ order.currentStatus.title }}</span>
          </v-chip>
        </div>
      </div>
    </div>

    <!-- departure details and order description -->
    <div class="sec-2">
      <!-- left -->
      <div class="packaging pr-4">
        <div class="image-2">
          <img
            src="@/assets/static/myOrders/singleCard/departureDate.svg"
            alt=""
          />
        </div>
        <div class="content">
          <div class="left">
            <h4 class="subtitle">Courier Service</h4>

            <small>{{ order.receiverInfo.courierService.title }}</small>
          </div>
          <div class="right">
            <p>{{ order.currentStatus.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hr"></div>

    <!-- reciver details -->
    <div class="sec-2 pr-4">
      <div class="reciever">
        <div class="img">
          <img src="@/assets/static/myOrders/singleCard/recievers.svg" alt="" />
        </div>
        <div class="data">
          <h4 class="subtitle">Recievers</h4>
          <small>{{ order.receiverInfo.name }}</small>
        </div>
      </div>
      <div class="address">
        <div class="img">
          <img
            src="@/assets/static/myOrders/singleCard/addressIcon.svg"
            alt=""
          />
        </div>
        <div class="">
          <h4 class="subtitle">Address</h4>
          <small
            >{{ order.receiverInfo.address1 }},
            {{ order.receiverInfo.address2 }},
            {{ order.receiverInfo.address3 }},
            {{ order.receiverInfo.address4 }}</small
          >
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: "cardOrder",
  props: ["order"],
  methods: {
    getDate(date) {
      const testDate = new Date(date.toDate());
      // console.log(date, testDate);
      return moment(testDate).format("D/MM/YYYY");
    },
    getTime(time) {
      const testTime = new Date(time.toDate());
      // console.log(time, testTime);
      return moment(testTime).format("h:mm A");
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card {
  min-width: 300px;
  width: 100%;
  max-width: 370px;
  border-radius: 18px;
  margin-right: 20px;
  margin-bottom: 20px !important;
}
a {
  text-decoration: none;
}
small {
  color: #4b4b4b;
}
.subtitle {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
}
.sec-1 {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 15px;
}
.orderid {
  display: flex;
  // justify-content:space-evenly;
}
.image-1,
.image-2,
.image-3,
.img {
  padding-right: 10px;
}
.content1 {
  // padding-left: 10px;
  font-size: 1.5rem;
}
.sec-2 {
  display: flex;
  padding: 10px;
  // justify-content:space-between;
}
.packaging {
  display: flex;
}
.weight {
  display: flex;
}
.information {
  display: flex;
  //   justify-content: space-between;
  //   padding: 10px;
}
.reciever {
  display: flex;
}
.button {
  padding: 10px;
}
.statusBadge {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  display: flex;
  justify-content: space-around;
}
.msg {
  margin-left: 12px;
}
.left {
  width: 50%;
}
.right {
  width: 50%;
  display: flex;
  border: 1px solid #454444;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  // width:100%;
  background: #f0ffe0;
  height: 51px;
  p {
    // width:80%;
    margin: 10px;
    padding: 4px;
    color: #2e5b00;
    font-size: 11px;
  }
}
.address {
  display: flex;
  padding-left: 10px;
}
.hr {
  margin-top: 0.5em;
  width: 90%;
  border-bottom: 1px solid #ececec;
}
@media (max-width: 780px) {
  .order-card {
    margin-right: 0px;
    margin-bottom: 3%;
    min-width: 300px;
    width: 100%;
    max-width: 300px;
  }
}
</style>
