<template>
  <div>
    <div id="not-available-wrap" v-if="orders.length <= 0">
      <div id="not-available">
        <img src="@/assets/static/gifs/no-order-found.gif" alt="" />

        <p>You Have No Orders</p>
        <v-btn color="#7d1286" class="white--text cta" to="myOrders/addNew"
          >Add Shipment</v-btn
        >
      </div>
    </div>
    <div id="content" v-else>
      <div id="search-wrap" class="mt-3">
        <div id="search-bar">
          <v-text-field
            @keyup="searchOnKeyUp()"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search using order ID"
            placeholder="Ex: CMB00001"
            counter
            maxlength="8"
          ></v-text-field>

          <v-btn
            @click="searchOrdersOfUser()"
            color="#7d1286"
            class="white--text cta ml-3"
          >
            GO
          </v-btn>
        </div>
      </div>
      <div class="order-Container">
        <MyOrdersCardOrder
          v-for="order in orders"
          :key="order.id"
          :order="order"
        />
      </div>

      <div class="paginationContainer">
        <!-- <v-btn :disabled="prev_btn" @click="previous"
          ><v-icon> mdi-chevron-left </v-icon></v-btn
        > -->
        <v-btn v-if="showLoadBtn" :disabled="next_btn" @click="next"
          >Load More</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import "firebase/auth";
import { db, auth } from "../../firebase";
import { mapGetters, mapActions } from "vuex";
import MyOrdersCardOrder from "../../components/myOrders/cardOrder.vue";

export default {
  name: "myOrders",
  components: { MyOrdersCardOrder },
  data: () => ({
    orders: [],
    limit: 9,
    lastVisible: "",
    firstVisible: "",
    next_btn: false,
    prev_btn: true,
    uId: "",

    //search
    showLoadBtn: true,

    //search
    search: "",
    actualOrders: [],
  }),
  created() {
    this.uId = auth.currentUser.uid;
  },
  mounted() {
    // run first query and bind data
    // this.$bind(
    //   "orders",
    //   db
    //     .collection("mailShipments")
    //     .where("uid", "==", this.uId)
    //     .orderBy("createdAt", "desc")
    //     .limit(this.limit)
    // );
    db.collection("mailShipments")
      .where("uid", "==", this.uId)
      .orderBy("createdAt", "desc")
      .limit(this.limit)
      .get()
      .then((querySnapshot) => {
        console.log("querySnapshot", querySnapshot);
        console.log("querySnapshot.docs", querySnapshot.docs);
        if (querySnapshot.length <= 0) return [];

        let shipment;
        querySnapshot.forEach((doc) => {
          shipment = doc.data();
          shipment.route = `myorders/${doc.id}`;
          this.orders.push(shipment);
        });
        console.log("shipments", this.orders);

        return this.orders;
      })
      .catch((error) => {
        console.error("error", error);
        return error;
      });

    // set last and first Visible
    db.collection("mailShipments")
      .where("uid", "==", this.uId)
      .orderBy("createdAt", "desc")
      .limit(this.limit)
      .get()
      .then((documentSnapshots) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
        this.firstVisible = documentSnapshots.docs[0];
      })
      .then(() => {
        // peep to check if next should be on or off
        db.collection("mailShipments")
          .where("uid", "==", this.uId)
          .orderBy("createdAt", "desc")
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((snap) => {
            if (snap.size === 0) {
              this.next_btn = true;
            }
          });
      });
  },
  computed: {
    ...mapGetters(["searchResult"]),
    ...mapGetters(["searchSuccess"]),
    ...mapGetters(["searchMsg"]),
  },
  methods: {
    ...mapActions(["searchShipment"]),

    next() {
      if (!this.next_btn) {
        // bind data with countries
        // this.$bind(
        //   "orders",
        //   db
        //     .collection("mailShipments")
        //     .where("uid", "==", this.uId)
        //     .orderBy("createdAt", "desc")
        //     .startAfter(this.lastVisible)
        //     .limit(this.limit)
        // );
        db.collection("mailShipments")
          .where("uid", "==", this.uId)
          .orderBy("createdAt", "desc")
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((querySnapshot) => {
            console.log("querySnapshot", querySnapshot);
            console.log("querySnapshot.docs", querySnapshot.docs);
            if (querySnapshot.length <= 0) return [];

            let shipment;
            querySnapshot.forEach((doc) => {
              shipment = doc.data();
              shipment.route = `myorders/${doc.id}`;
              this.orders.push(shipment);
            });
            this.actualOrders = this.orders;
            console.log("shipments", this.orders);

            return this.orders;
          })
          .catch((error) => {
            console.error("error", error);
            return error;
          });
        // set last and first visible items
        db.collection("mailShipments")
          .where("uid", "==", this.uId)
          .orderBy("createdAt", "desc")
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((documentSnapshots) => {
            this.lastVisible =
              documentSnapshots.docs[documentSnapshots.docs.length - 1];
            this.firstVisible = documentSnapshots.docs[0];
            console.log(
              "the length of next documents fetched",
              documentSnapshots.docs.length
            );
          })
          .then(() => {
            // Peep  on the next  next query to see if it gives zero
            db.collection("mailShipments")
              .where("uid", "==", this.uId)
              .orderBy("createdAt", "desc")
              .startAfter(this.lastVisible)
              .limit(this.limit)
              .get()
              .then((snap) => {
                if (snap.size === 0) {
                  //disable button if the next peeped result gets zero
                  this.next_btn = true;
                  // enable previous button
                  this.prev_btn = false;
                } else {
                  // enable next button if peeped result is not zero
                  this.next_btn = false;
                  // enable previous button
                  this.prev_btn = false;
                }
              });
          });
      }
    },
    previous() {
      // Ensure previous is not zero
      db.collection("mailShipments")
        .where("uid", "==", this.uId)
        .orderBy("createdAt", "desc")
        .endBefore(this.firstVisible)
        .limitToLast(this.limit)
        .get()
        .then((snap) => {
          return snap.size;
        })
        .then((size) => {
          //confirm is not zero here
          if (size !== 0) {
            //bind the previous to countries
            // this.$bind(
            //   "orders",
            //   db
            //     .collection("mailShipments")
            //     .where("uid", "==", this.uId)
            //     .orderBy("createdAt", "desc")
            //     .endBefore(this.firstVisible)
            //     .limitToLast(this.limit)
            // );
            db.collection("mailShipments")
              .where("uid", "==", this.uId)
              .orderBy("createdAt", "desc")
              .endBefore(this.firstVisible)
              .limitToLast(this.limit)
              .get()
              .then((querySnapshot) => {
                console.log("querySnapshot", querySnapshot);
                console.log("querySnapshot.docs", querySnapshot.docs);
                if (querySnapshot.length <= 0) return [];

                let shipment;
                querySnapshot.forEach((doc) => {
                  shipment = doc.data();
                  shipment.route = `myorders/${doc.id}`;
                  this.orders.push(shipment);
                });
                this.actualOrders = this.orders;

                console.log("shipments", this.orders);

                return this.orders;
              })
              .catch((error) => {
                console.error("error", error);
                return error;
              });

            // Set last and first visible
            db.collection("mailShipments")
              .where("uid", "==", this.uId)
              .orderBy("createdAt", "desc")
              .endBefore(this.firstVisible)
              .limitToLast(this.limit)
              .get()
              .then((documentSnapshots) => {
                this.lastVisible =
                  documentSnapshots.docs[documentSnapshots.docs.length - 1];
                this.firstVisible = documentSnapshots.docs[0];
              })
              .then(() => {
                // peep the next previous query
                db.collection("mailShipments")
                  .where("uid", "==", this.uId)
                  .orderBy("createdAt", "desc")
                  .endBefore(this.firstVisible)
                  .limitToLast(this.limit)
                  .get()
                  .then((snap) => {
                    if (snap.size === 0) {
                      //if next peeped previous button gets 0 disable
                      this.prev_btn = true;
                      this.next_btn = false;
                    } else {
                      //if next peeped result is does not get 0 enable buttons
                      this.prev_btn = false;
                      this.next_btn = false;
                    }
                  });
              });
          }
        });
    },
    //search
    searchOnKeyUp() {
      console.log("searchKeyup", this.search);
      if (this.search == null || this.search.length == 0) {
        console.log("this.actualOrders", this.actualOrders);
        this.orders = this.actualOrders;
        this.showLoadBtn = true;

        console.log("len > 0");
      }
    },

    async searchOrdersOfUser() {
      const payLoad = {
        keyword: this.search,
      };
      await this.searchShipment(payLoad)
        .then(() => {
          console.log("searchResult", this.searchResult);
          if (this.searchSuccess == true)
            if (this.searchResult.length > 0) {
              this.actualOrders = this.orders;
              this.orders = this.searchResult;
              this.showLoadBtn = false;
            } else alert("No Shipment found for this Order ID");
          else alert(this.searchMsg);
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#not-available-wrap {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#not-available {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
    border-radius: 500px;
  }
  p {
    margin-top: 10px;
    color: rgb(44, 0, 42);
  }
}
.statusCode {
  width: 95%;
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#search-bar {
  background-color: white;
  border-radius: 5px;
  padding: 2px 15px;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-Container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 3%;
  padding-top: 3%;
}
// Pagination Buttons Container
.paginationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
  .v-btn {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(187, 87, 196);
    color: white;
    font-weight: bold;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: rgba(187, 87, 196, 0.54);
      color: 5e35b1;
    }
  }
}

@media (max-width: 780px) {
  .order-Container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 0px;
  }
}
</style>